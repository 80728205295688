<template>
    <div class="retrieve_trackit">
        <Top name="物品找回记录" back email></Top>
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="height: calc(100% - 40px);background-color: #ececec;overflow-y: auto;">
            <div class="wallet_w">
                <van-list
                    class="list_side"
                    v-model="loading"
                    :finished="finished"
                    offset="50"
                    finished-text="没有更多了"
                    :error.sync="error"
                    error-text="请求失败，点击重新加载"
                    @load="onLoad"
                >
                    <van-cell v-for="item in list" :key="item.id">
                        <div class="detail_list">
                            <div class="detail_list_t">
                                <span>{{item.channel_name}}【{{item.lost_goods}}】</span>
                            </div>
                            <div class="detail_list_b">
                                <span>{{item.createTime}}&ensp;<span :class="{blue:item.status==0,green:item.status==1,orange:item.status==2}">{{item.status_name}}</span></span>
                                <span class="btn blue" v-if="item.status==0" @click="operationfn(item)">取消申请</span>
                                <span class="btn" v-else-if="item.status==1" @click="operationfn(item)">删除记录</span>
                                <span  @click="operationfn(item)" v-else class="btn orange">再次申请</span>
                            </div>
                        </div>
                    </van-cell>
                    <!-- <van-cell>
                        <div class="detail_list">
                            <div class="detail_list_t">
                                <span>仙境传奇【炼妖丹*10、起源斗笠*1、起源盾牌*1、起源鬼甲*1、起源诛仙*1、起源的靴*1、起源的镯*1】</span>
                            </div>
                            <div class="detail_list_b">
                                <span>2021/06/09（发起时间）<span class="blue">审核中</span></span>
                                <span class="btn blue">取消申请</span>
                            </div>
                        </div>
                    </van-cell>
                    <van-cell>
                        <div class="detail_list">
                            <div class="detail_list_t">
                                <span>仙境传奇【炼妖丹*10、起源斗笠*1、起源盾牌*1、起源鬼甲*1、起源诛仙*1、起源的靴*1、起源的镯*1】</span>
                            </div>
                            <div class="detail_list_b">
                                <span>2021/06/09（发起时间）<span class="green">找回成功</span></span>
                                <span class="btn">删除记录</span>
                            </div>
                        </div>
                    </van-cell>
                    <van-cell>
                        <div class="detail_list">
                            <div class="detail_list_t">
                                <span>仙境传奇【炼妖丹*10、起源斗笠*1、起源盾牌*1、起源鬼甲*1、起源诛仙*1、起源的靴*1、起源的镯*1】</span>
                            </div>
                            <div class="detail_list_b">
                                <span>2021/06/09（发起时间）<span class="orange">找回失败</span></span>
                                <span class="btn orange" @click="recaptionShow=true">再次申请</span>
                            </div>
                        </div>
                    </van-cell> -->
                </van-list>
            </div>
        </van-pull-refresh>
        <div class="pop_wrap">
            <van-popup v-model="recaptionShow" position="top">
                <div class="pop_w">
                    <p>{{tips.status==0?"是否确认取消申请？":"是否确认删除此条记录"}}</p>
                </div>
                <div class="pop_btns">
                    <van-button round type="info" size="small" color="linear-gradient(to right, #FF704D, #F73727)" @click="notarizeFn()">确定</van-button>
                    <van-button plain round type="default" size="small" @click="recaptionShow=false;">取消</van-button>
                </div>
            </van-popup>
        </div>
        
    </div>
</template>
<script>
import Top from '@/components/top.vue'
export default {
    name: 'RetrieveTrackit',
    components: {
        Top,
    },
    data() {
        return {
            isLoading:false,
            active: 1,
            loading: false,
            error: false,
            finished: false,
            recaptionShow: false,
            list: [],
            tips:{},
            page:1
        }
    },
    created(){
        // let username = localStorage.getItem('username');
        // this.$api.helper.itemRecoveryList({page:this.page}).then((res)=>{
        //     this.list = res.data.list;
        // })
    },
    methods: {
        onRefresh(){
            this.page = 1;
            this.loading = true;
            this.list=[];
            this.finished = false;
            this.onLoad();
        },
        // 弹窗确定按钮
        notarizeFn(){
            let data = {
                id:this.tips.id,
                status:3
            }
            this.$api.helper.editItemRecovery(data).then((res)=>{
                if(res.code==200){
                    this.recaptionShow=false;
                    this.$toast({
                        message: '操作成功',
                        duration: 1500
                    });
                    // let username = localStorage.getItem('username');
                    this.page = 1;
                    this.loading = true;
                    this.list=[];
                    this.finished = false;
                    this.onLoad();
                    // this.$api.helper.itemRecoveryList({page:this.page}).then((res)=>{
                    //     this.list = res.data.list;
                    //     this.loading = true;
                    // })
                }
            })
        },
        // 删除记录 取消申请 再次申请
        operationfn(obj){
            if(obj.status==2){
                // var arr=JSON.stringify(obj)
                
                this.$router.push({name:'trackit',params: obj})
                return false
            }
            this.recaptionShow = true;
            this.tips = obj;
        },
        //tab切换
        tabsClick(name) {
            //console.log(name);
        },
        //列表加载
        onLoad() {
            this.$api.helper.itemRecoveryList({page:this.page}).then(res => {
                if (res.code == 200) {
                    if (res.data.list.length > 0) {
                        this.list = this.list.concat(res.data.list);
                        this.list.forEach(item => {
                            item.createTime = item.createTime.substring(0, 10) + ' (发起时间)';
                        });
                        if (this.page * 8  >= res.data.count) {
                            this.finished = true;
                        }
                        this.loading = false;
                        ++this.page;
                    }else{
                        //加载状态结束
                        this.loading = false;
                        //数据全部加载完成
                        this.finished = true;
                    }
                }else{
                    //加载状态结束
                    this.loading = false;
                    //错误提示
                    this.error = true;
                }
                this.isLoading = false;
            }).catch(err => {
                this.isLoading = false;
                console.log(err);
            });
            // 异步更新数据
        // setTimeout 仅做示例，真实场景中一般为 ajax 请求
        //     setTimeout(() => {
        //         for (let i = 0; i < 10; i++) {
        //             this.list.push({
        //                 reason: '提现失败退回',
        //                 money: '+2000',
        //                 time: '2021/06/09 16:35',
        //                 balance: '34000'
        //             });
        //         }

        //         // 加载状态结束
        //         this.loading = false;

        //         // 数据全部加载完成
        //         if (this.list.length >= 10) {
        //             this.finished = true;
        //         }
        //     }, 1000);
        },
    }
}
</script>
<style scoped>
.wallet_w{
    background-color: #ececec;
}
.wallet_w .van-cell{
    background: none;
    padding: 6px 16px;
}
.list_side{
    margin-top: .84375rem;
}
.list_side .van-cell::after{
    border-bottom: none;
}
.detail_list{
    background-color: #fff;
    border-radius: 5px;
    padding: .5rem .8125rem 0;
}
.detail_list_t,
.detail_list_b{
    display: flex;
    justify-content: space-between;
    font-size: .8125rem;
    /* line-height: 1.75rem; */
    border-bottom: 1px dashed #ddd;
}
.detail_list_b{
    font: .875rem/2.1875rem Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
    color: #acacac;
    align-items: center;
}
.detail_list_b{
    border-bottom: none;
}
.detail_list_t span:first-child{
    color: #313131;
}
.detail_list_t span{
    font: .75rem/1.125rem Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
    color: #313131;
    padding-bottom: .3125rem;
}
.list_side{
    margin-top: 0;
    padding-bottom: 50px;
    background-color: #ececec;
}
.retrieve_trackit{
    height: 100%;
}
.wallet_w{
    height: 100%;
}
.btn{
    color: #707070;
    border: 1px solid #707070;
    border-radius: .625rem;
    font: .875rem/1.25rem Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
    height: 1.25rem;
    vertical-align: middle;
    padding:.0625rem .625rem;
}
.blue{
    color: #227eeb;
    border-color: #227eeb;
}
.green{
    color: #2f9009;
}
.orange{
    color: #ff4e00;
    border-color: #ff4e00;
}
</style>
<style>
/* .wallet_w .van-tabs__wrap{
    border-bottom: 1px solid #ddd;
}
.wallet_w .van-tabs__wrap .van-tabs__line{
    background-color: #227eeb;
}
.wallet_w .van-tabs__wrap .van-tab--active{
    font-weight: 700;
} */
</style>